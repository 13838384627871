const ResumeBody = () => {

    return (
        <div>
            <section className="main">
                <div className="main-cont">
                    <div className="text">
                        <p>
                        RESUME COMING SOON! Please email to request a PDF copy.
                        </p> 
                    </div>
                    <div className="pic pic-resume"></div>
                </div>
            </section>
        </div>
    )
}


export default ResumeBody;